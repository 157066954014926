<template>
  <div style="height: 100%; width: 100%" class="fadeIn">
    <div class="header">
      <div style="font-size: 16px">
        查询方式 ：<i
          @click="dateVisible = true"
          class="el-icon-date"
          style="font-size: 16px; color: blue"
        ></i>
      </div>
    </div>
    <div class="oeebody">
      <div id="barBoxtwo"></div>
    </div>
    <el-dialog
      title="查询方式："
      width="90%"
      style="text-align: left"
      :visible.sync="dateVisible"
    >
      <van-notice-bar
        v-if="dateVisible"
        text="周查询和月查询为本年所有周和所有月,无需上传日期！"
      />
      选择方式：
      <van-radio-group
        v-model="radio"
        direction="horizontal"
        @change="getshiftGroupList"
      >
        <van-radio :name="1" icon-size="14px">日查询</van-radio>
        <van-radio :name="2" icon-size="14px">班查询</van-radio>
        <van-radio :name="3" icon-size="14px">周查询</van-radio>
        <van-radio :name="4" icon-size="14px">月查询</van-radio>
      </van-radio-group>
      <el-row v-if="radio == 1 || radio == 2">
        <div style="margin: 10px 0">
          开始日期：
          <el-date-picker
            v-model="dateValue[0]"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </div>
        <div style="margin: 10px 0">
          结束日期：
          <el-date-picker
            v-model="dateValue[1]"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </div>
        <span v-if="radio == 2">
          <van-field
            v-model="fieldValue"
            is-link
            readonly
            label="班组"
            placeholder="请选择班组"
            @click="show = true"
          />
          <van-popup v-model="show" round position="bottom">
            <van-picker
              show-toolbar
              :columns="columns"
              @confirm="onConfirm"
              @cancel="onCancel"
            />
          </van-popup>
        </span>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="getUseRate()">确 定</el-button>
        <el-button @click="dateVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
let that;
import * as echarts from "echarts";
export default {
  data() {
    return {
      shiftGroupvalue: "",
      columns: [],
      fieldValue: "",
      show: false,
      shiftName: [],
      weekDate: [],
      radio: 1,
      dateVisible: false,
      barData: [],
      dateValue: [],
      // 分析图
      setBar(data, Xdata, ref) {
        let option = {
          title: {
            text: "灯时长总计",
            subtext: "Total Duration",
            left: "left",
            textStyle: {
              fontSize: 12,
            },
          },
          tooltip: {
            position: function (point, params, dom, rect, size) {
              // 鼠标坐标和提示框位置的参考坐标系是：以外层div的左上角那一点为原点，x轴向右，y轴向下
              // 提示框位置
              var x = 0; // x坐标位置
              var y = 0; // y坐标位置

              // 当前鼠标位置
              var pointX = point[0];
              var pointY = point[1];

              // 外层div大小
              // var viewWidth = size.viewSize[0];
              // var viewHeight = size.viewSize[1];

              // 提示框大小
              var boxWidth = size.contentSize[0];
              var boxHeight = size.contentSize[1];

              // boxWidth > pointX 说明鼠标左边放不下提示框
              if (boxWidth > pointX) {
                x = 5;
              } else {
                // 左边放的下
                x = pointX - boxWidth;
              }

              // boxHeight > pointY 说明鼠标上边放不下提示框
              if (boxHeight > pointY) {
                y = 5;
              } else {
                // 上边放得下
                y = pointY - boxHeight;
              }

              return [x, y];
            },
            trigger: "axis",
            axisPointer: {
              type: "cross",
            },
            formatter: function (params, index) {
              let label;
              if (params.length > 6) {
                label =
                  params[0].axisValue + "<br/>" + that.shiftName[0] + "<br/>";
              } else {
                if (that.radio == 3) {
                  let index = params[0].dataIndex;
                  label =
                    params[0].axisValue +
                    "<br/>" +
                    '<span style="color:#409eff">' +
                    "开始日期:" +
                    "  " +
                    that.weekDate[index]["start"] +
                    "<br/>" +
                    "结束日期:" +
                    " " +
                    that.weekDate[index]["end"] +
                    "<br/>" +
                    "</span>";
                } else {
                  label = params[0].axisValue + "<br/>";
                }
              }
              let arr = [];
              let all = "";
              let allt = "";
              let allT = "";
              if (params.length > 4) {
                all = Number(
                  params[0].data * 3600 +
                    params[1].data * 3600 +
                    params[2].data * 3600 +
                    params[3].data * 3600 +
                    params[4].data * 3600
                );
              }
              if (params.length > 8) {
                allt = Number(
                  params[5].data * 3600 +
                    params[6].data * 3600 +
                    params[7].data * 3600 +
                    params[8].data * 3600 +
                    params[9].data * 3600
                );
              }
              if (params.length > 11) {
                allT = Number(
                  params[10].data * 3600 +
                    params[11].data * 3600 +
                    params[12].data * 3600 +
                    params[13].data * 3600 +
                    params[14].data * 3600
                );
              }
              let percentage = [];
              params.forEach((item, index) => {
                let data = item.data * 3600;
                let str = "";
                if (data / 3600 >= 1) {
                  str = str + (data - (data % 3600)) / 3600 + "小时";
                }
                if ((data % 3600) / 60 >= 1) {
                  str = str + ((data % 3600) - (data % 60)) / 60 + "分";
                }
                str = str + Math.floor(data % 60) + "秒";
                arr.push(str);
                if (index < 5) {
                  if (all == 0) {
                    percentage.push(0);
                  } else {
                    percentage.push(((data / all) * 100).toFixed(2) * 1);
                  }
                } else if (index > 4 && index < 10) {
                  if (allt == 0) {
                    percentage.push(0);
                  } else {
                    percentage.push(((data / allt) * 100).toFixed(2) * 1);
                  }
                } else if (index > 9) {
                  if (allT == 0) {
                    percentage.push(0);
                  } else {
                    percentage.push(((data / allT) * 100).toFixed(2) * 1);
                  }
                }
                if (data) {
                  if (index == 5) {
                    return (label +=
                      that.shiftName[1] +
                      "<br/>" +
                      item.marker +
                      item.seriesName +
                      '<span style="color:' +
                      item.color +
                      ';display:inline-block;margin:2px 0px 1px 30px">' +
                      arr[index] +
                      '<span style=" font-weight: 700;">' +
                      "(" +
                      percentage[index] +
                      "%" +
                      ")" +
                      "</span>" +
                      "</span>" +
                      "<br/>");
                  } else if (index == 10) {
                    return (label +=
                      that.shiftName[2] +
                      "<br/>" +
                      item.marker +
                      item.seriesName +
                      '<span style="color:' +
                      item.color +
                      ';display:inline-block;margin:2px 0px 1px 30px">' +
                      arr[index] +
                      '<span style=" font-weight: 700;">' +
                      "(" +
                      percentage[index] +
                      "%" +
                      ")" +
                      "</span>" +
                      "</span>" +
                      "<br/>");
                  } else {
                    return (label +=
                      item.marker +
                      item.seriesName +
                      '<span style="color:' +
                      item.color +
                      ';display:inline-block;margin:2px 0px 1px 30px">' +
                      arr[index] +
                      '<span style=" font-weight: 700;">' +
                      "(" +
                      percentage[index] +
                      "%" +
                      ")" +
                      "</span>" +
                      "</span>" +
                      "<br/>");
                  }
                }
              });
              return '<div style="text-align:left">' + label + "</div>";
            },
          },
          legend: {
            right: 10,
            icon: "circle",
            textStyle: {
              fontsize: 10,
              padding: [0, 0, 0, -10],
            },
            itemHeight: 10, //修改图形大小
            itemGap: 15, //修改间距
          },
          grid: {
            left: "0",
            right: "8%",
            bottom: "3%",
            containLabel: true,
          },
          dataZoom: [
            {
              type: "inside",
              yAxisIndex: [0],
              start: 0,
              end: 100,
            },
          ],
          xAxis: [
            {
              type: "value",
              alignTicks: true,
              axisLabel: {
                formatter: "{value} 时",
                rotate: 30,
              },
            },
          ],
          yAxis: [
            {
              type: "category",
              data: Xdata,
              axisLabel: {
                //文字倾斜
                interval: 0,
                rotate: 30,
              },
            },
          ],
          series: data,
        };
        var chartDom = document.getElementById(ref);
        let myChart = echarts.init(chartDom);
        myChart.clear();
        myChart.setOption(option);
      },
    };
  },
  methods: {
    onConfirm({ text, value }) {
      this.fieldValue = text;
      this.shiftGroupvalue = value;
      this.show = false;
    },
    onCancel() {
      this.show = false;
    },
    getshiftGroupList(val) {
      if (val == 2) {
        this.$api.shiftSet
          .allShiftList(this.$route.query.projectGroupId)
          .then((res) => {
            if (res.code == 200 && res.data) {
              this.columns = res.data.map((item) => ({
                text: item.shiftGroupName,
                value: item.shiftGroupId,
                disabled: !item.shift,
              }));
            }
          });
      }
    },
    getUseRate() {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
        overlay: true,
      });
      let from = {
        projectGroupId: this.$route.query.projectGroupId,
        startDate: this.dateValue[0],
        endDate: this.dateValue[1],
        type: this.radio,
        shiftGroupId: this.shiftGroupvalue,
      };
      this.$api.triColorLamp.trendAllList(from).then((res) => {
        this.$toast.clear();
        if (res.code == 200) {
          that.dateVisible = false;
          if (this.radio == 2) {
            that.searchShift(res);
          } else {
            let red = [];
            let yellow = [];
            let grey = [];
            let green = [];
            let blue = [];
            let Xdata = [];
            res.data.forEach((item) => {
              if (that.radio == 3) {
                let obj = {
                  start: item.startTime,
                  end: item.endTime,
                };
                that.weekDate.push(obj);
              }
              let newGrey = (item.duration["0"] / 3600).toFixed(2) * 1;
              let newred = (item.duration["1"] / 3600).toFixed(2) * 1;
              let newyellow = (item.duration["2"] / 3600).toFixed(2) * 1;
              let newgreen = (item.duration["3"] / 3600).toFixed(2) * 1;
              let newblue = (item.duration["4"] / 3600).toFixed(2) * 1;
              red.push(newred);
              yellow.push(newyellow);
              green.push(newgreen);
              grey.push(newGrey);
              blue.push(newblue);
              if (that.radio == 1 || that.radio == 2) {
                Xdata.push(item["date"].substring(5));
              } else {
                Xdata.push(item["date"]);
              }
            });
            let data = [
              {
                name: "绿灯",
                type: "bar",
                stack: "Ad",
                barMaxWidth: 40,
                emphasis: {
                  focus: "none",
                  disabled: true,
                },
                itemStyle: {
                  color: "rgb(96,185,51)",
                },
                data: green,
              },
              {
                name: "黄灯",
                type: "bar",
                stack: "Ad",
                barMaxWidth: 40,
                emphasis: {
                  focus: "none",
                  disabled: true,
                },
                itemStyle: {
                  color: "rgb(255,185,51)",
                },
                data: yellow,
              },
              {
                name: "红灯",
                type: "bar",
                stack: "Ad",
                barMaxWidth: 40,
                emphasis: {
                  focus: "none",
                  disabled: true,
                },
                itemStyle: {
                  color: "red",
                },
                data: red,
              },
              {
                name: "蓝灯",
                type: "bar",
                stack: "Ad",
                barMaxWidth: 40,
                emphasis: {
                  focus: "none",
                  disabled: true,
                },
                itemStyle: {
                  color: "#409eff",
                },
                data: blue,
              },
              {
                name: "灭灯",
                type: "bar",
                stack: "Ad",
                barMaxWidth: 40,
                emphasis: {
                  focus: "none",
                  disabled: true,
                },
                itemStyle: {
                  color: "grey",
                },
                data: grey,
              },
            ];
            this.$nextTick(() => {
              this.setBar(data, Xdata, "barBoxtwo");
            });
          }
        }
      });
    },
    // 稼动率趋势按照班次查询
    searchShift(res) {
      if (res.data.length < 1) {
        this.$message.warning("暂无班次信息");
        return;
      }
      that.shiftName = [];
      let red = [];
      let yellow = [];
      let grey = [];
      let green = [];
      let blue = [];

      let tgreen = [];
      let tred = [];
      let tyellow = [];
      let tgrey = [];
      let tblue = [];

      let Tred = [];
      let Tyellow = [];
      let Tgrey = [];
      let Tgreen = [];
      let Tblue = [];

      let Xdata = [];
      res.data.forEach((it, index) => {
        if (index == 0) {
          it.shifts.forEach((ite) => {
            that.shiftName.push(ite.shiftName);
          });
        }
        Xdata.push(it.date);
        it.shifts.forEach((item, index) => {
          if (index == 0) {
            let newGrey = (item.duration["0"] / 3600).toFixed(2) * 1;
            let newred = (item.duration["1"] / 3600).toFixed(2) * 1;
            let newyellow = (item.duration["2"] / 3600).toFixed(2) * 1;
            let newgreen = (item.duration["3"] / 3600).toFixed(2) * 1;
            let newblue = (item.duration["4"] / 3600).toFixed(2) * 1;
            red.push(newred);
            yellow.push(newyellow);
            green.push(newgreen);
            grey.push(newGrey);
            blue.push(newblue);
          } else if (index == 1) {
            let newGrey = (item.duration["0"] / 3600).toFixed(2) * 1;
            let newred = (item.duration["1"] / 3600).toFixed(2) * 1;
            let newyellow = (item.duration["2"] / 3600).toFixed(2) * 1;
            let newgreen = (item.duration["3"] / 3600).toFixed(2) * 1;
            let newblue = (item.duration["4"] / 3600).toFixed(2) * 1;
            tred.push(newred);
            tyellow.push(newyellow);
            tgreen.push(newgreen);
            tgrey.push(newGrey);
            tblue.push(newblue);
          } else if (index == 2) {
            let newGrey = (item.duration["0"] / 3600).toFixed(2) * 1;
            let newred = (item.duration["1"] / 3600).toFixed(2) * 1;
            let newyellow = (item.duration["2"] / 3600).toFixed(2) * 1;
            let newgreen = (item.duration["3"] / 3600).toFixed(2) * 1;
            let newblue = (item.duration["4"] / 3600).toFixed(2) * 1;
            Tred.push(newred);
            Tyellow.push(newyellow);
            Tgreen.push(newgreen);
            Tgrey.push(newGrey);
            Tblue.push(newblue);
          }
        });
      });
      // let flge1 = blue.reduce((pre, curr) => {
      //   return pre+=curr
      // }, 0)
      // let flge2 = tblue.reduce((pre, curr) => {
      //   return pre+=curr
      // }, 0)
      // let flge3 = Tblue.reduce((pre, curr) => {
      //   return pre+=curr
      // },0)
      let flge1 = 1;
      let flge2 = 1;
      let flge3 = 1;
      let data1 = [
        {
          name: "绿灯",
          type: "bar",
          stack: "Ad",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "rgb(96,185,51)",
          },
          data: green,
        },
        {
          name: "黄灯",
          type: "bar",
          stack: "Ad",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "rgb(255,185,51)",
          },
          data: yellow,
        },
        {
          name: "红灯",
          type: "bar",
          stack: "Ad",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "red",
          },
          data: red,
        },
        {
          name: "灭灯",
          type: "bar",
          stack: "Ad",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "grey",
          },
          data: grey,
        },
      ];
      let data2 = [
        {
          name: "绿灯",
          type: "bar",
          stack: "AC",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "rgb(96,185,51)",
          },
          data: tgreen,
        },
        {
          name: "黄灯",
          type: "bar",
          stack: "AC",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "rgb(255,185,51)",
          },
          data: tyellow,
        },
        {
          name: "红灯",
          type: "bar",
          stack: "AC",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "red",
          },
          data: tred,
        },
        {
          name: "灭灯",
          type: "bar",
          stack: "AC",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "grey",
          },
          data: tgrey,
        },
      ];
      let data3 = [
        {
          name: "绿灯",
          type: "bar",
          stack: "AE",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "rgb(96,185,51)",
          },
          data: Tgreen,
        },
        {
          name: "黄灯",
          type: "bar",
          stack: "AE",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "rgb(255,185,51)",
          },
          data: Tyellow,
        },
        {
          name: "红灯",
          type: "bar",
          stack: "AE",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "red",
          },
          data: Tred,
        },
        {
          name: "灭灯",
          type: "bar",
          stack: "AE",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "grey",
          },
          data: Tgrey,
        },
      ];
      if (flge1 > 0) {
        data1.push({
          name: "蓝灯",
          type: "bar",
          stack: "Ad",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "#409eff",
          },
          data: blue,
        });
      }
      if (flge2 > 0) {
        data2.push({
          name: "蓝灯",
          type: "bar",
          stack: "AC",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "#409eff",
          },
          data: tblue,
        });
      }
      if (flge3 > 0) {
        data3.push({
          name: "蓝灯",
          type: "bar",
          stack: "AE",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "#409eff",
          },
          data: Tblue,
        });
      }
      let newdata = [];
      if (res.data[0].shifts.length == 2) {
        newdata = [...data1, ...data2];
      } else {
        newdata = [...data1, ...data2, ...data3];
      }
      that.setBar(newdata, Xdata, "barBoxtwo");
    },
  },
  mounted() {
    that = this;
    let date = new Date();
    let full = date.getFullYear();
    let month =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    let Day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let endDate = full + "-" + month + "-" + Day;
    if (Day > 6) {
      Day -= 6;
      Day = Day < 10 ? "0" + Day : Day;
    } else {
      if (month - 1 < 1) {
        full -= 1;
        month = 12;
      } else {
        month -= 1;
        if (month < 10) {
          month = "0" + month;
        }
      }
      Day = new Date(full, month, 0).getDate() + Day * 1 - 6;
    }
    let startDate = full + "-" + month + "-" + Day;
    this.dateValue = [startDate, endDate];
    this.getUseRate();
  },
};
</script>
<style scoped>
* {
  box-sizing: border-box;
}
.header {
  height: 50px;
  padding: 0px 20px;
  border-radius: 15px 15px 0px 0px;
  width: 98%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.oeebody {
  height: calc(100% - 50px);
  width: 100%;
  padding: 5px 5px;
  box-sizing: border-box;
}
#barBoxtwo {
  width: 100%;
  /* height: 100%; */
  height: 75vh;
  border: 1px solid #dee1e6;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
</style>
