<template>
  <div style="width: 100%; height: 100%" class="fadeIn">
    <van-nav-bar
      title="数据分析图"
      left-text="返回"
      left-arrow
      @click-left="$router.go(-1)"
    />
    <van-tabs v-model="activeName" @change="tabsChange">
      <van-tab title="稼动率详情" name="a">
        <div class="header">
          <div style="font-size: 16px">
            查询方式 ：<i
              @click="dateVisible = true"
              class="el-icon-date"
              style="font-size: 16px; color: blue"
            ></i>
          </div>
        </div>
        <el-dialog
          title="查询方式："
          width="90%
    "
          style="text-align: left"
          :visible.sync="dateVisible"
        >
          选择方式：
          <van-radio-group
            v-model="radio"
            direction="horizontal"
            @change="getShiftGroup"
          >
            <van-radio :name="1" icon-size="14px">日查询</van-radio>
            <van-radio :name="2" icon-size="14px">班查询</van-radio>
            <van-radio :name="3" icon-size="14px">周查询</van-radio>
            <van-radio :name="4" icon-size="14px">月查询</van-radio>
          </van-radio-group>
          <el-row v-if="radio == 1 || radio == 2">
            <div style="margin: 10px 0">
              选择日期：
              <el-date-picker
                v-model="dateValue"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
                @change="datechange($event)"
              >
              </el-date-picker>
            </div>
          </el-row>
          <el-row v-else-if="radio == 3" style="margin: 10px 0">
            选择周：
            <el-date-picker
              :key="radio + 1"
              v-model="weekValue"
              type="week"
              format="yyyy 第 WW 周"
              placeholder="选择周"
              value-format="yyyy-MM-dd"
              :picker-options="{ firstDayOfWeek: 1 }"
              @change="datechange($event)"
            >
            </el-date-picker>
          </el-row>
          <el-row v-else style="margin: 10px 0">
            选择月份：
            <el-date-picker
              :key="radio + 2"
              v-model="monthValue"
              type="month"
              placeholder="选择月"
              value-format="yyyy-MM"
              :picker-options="pickerOptions"
              @change="datechange($event)"
            >
            </el-date-picker>
          </el-row>
          <el-row v-if="radio == 2" type="flex">
            <van-field
              v-model="fieldValue"
              is-link
              readonly
              label="班次"
              placeholder="请选择班次"
              @click="show = true"
            />
            <van-popup v-model="show" round position="bottom">
              <van-cascader
                v-model="shiftValue"
                title="请选择班次"
                :options="options"
                @close="show = false"
                @change="onChange"
                @finish="onFinish"
              />
            </van-popup>
            <!-- 选择班次：
            <van-radio-group v-model="shiftValue" direction="horizontal">
              <van-radio
                v-for="item in radioData"
                :key="item.value"
                :name="item.value"
                >{{ item.label }}</van-radio
              >
            </van-radio-group> -->
          </el-row>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="getUseRate">确 定</el-button>
            <el-button @click="dateVisible = false">取 消</el-button>
          </div>
        </el-dialog>
        <div class="oeebody">
          <div id="barBox"></div>
          <div id="pieBox">
            <div id="pie1"></div>
            <div id="pie2"></div>
            <div id="ranking"></div>
          </div></div
      ></van-tab>
      <van-tab title="稼动率趋势" name="b">
        <trendChart ref="trendChart" v-if="activeName == 'b'"></trendChart>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
let that;
import * as echarts from "echarts";
import trendChart from "./trendChart.vue";
// import chartMixin from "@/mixins/chart";
export default {
  // mixins: [chartMixin],
  components: {
    trendChart,
  },
  data() {
    return {
      show: false,
      fieldValue: "",
      options: [],
      // chartRef: ["chart"],
      radioData: [],
      shiftValue: "",
      addDate: "",
      weekValue: "",
      monthValue: "",
      activeName: "a",
      shiftName: [],
      weekDate: [],
      radio: 1,
      dateVisible: false,
      barData: [],
      dateValue: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      setBar(data, xdata, ref) {
        let state = data[4].data.reduce((pre, curr) => {
          return (pre += curr);
        }, 0);
        let legendData = [];
        data.forEach((item, index) => {
          if (state) {
            legendData.push(item.name);
          } else {
            if (index !== 4) {
              legendData.push(item.name);
            }
          }
        });
        let color = "#383838";
        let option = {
          tooltip: {
            position: function (point, params, dom, rect, size) {
              // 鼠标坐标和提示框位置的参考坐标系是：以外层div的左上角那一点为原点，x轴向右，y轴向下
              // 提示框位置
              var x = 0; // x坐标位置
              var y = 0; // y坐标位置

              // 当前鼠标位置
              var pointX = point[0];
              var pointY = point[1];

              // 外层div大小
              // var viewWidth = size.viewSize[0];
              // var viewHeight = size.viewSize[1];

              // 提示框大小
              var boxWidth = size.contentSize[0];
              var boxHeight = size.contentSize[1];

              // boxWidth > pointX 说明鼠标左边放不下提示框
              if (boxWidth > pointX) {
                x = 5;
              } else {
                // 左边放的下
                x = pointX - boxWidth;
              }

              // boxHeight > pointY 说明鼠标上边放不下提示框
              if (boxHeight > pointY) {
                y = 5;
              } else {
                // 上边放得下
                y = pointY - boxHeight;
              }

              return [x, y];
            },
            trigger: "axis",
            axisPointer: {
              type: "cross",
            },
            formatter: function (params, index) {
              let label = params[0].axisValue + "<br/>";
              let arr = [];
              let all = Number(
                params[0].data * 3600 +
                  params[1].data * 3600 +
                  params[2].data * 3600 +
                  params[3].data * 3600 +
                  params[4].data * 3600
              );
              let percentage = [];
              params.forEach((item, index) => {
                let data = item.data * 3600;
                let str = "";
                if (data / 3600 >= 1) {
                  str = str + (data - (data % 3600)) / 3600 + "小时";
                }
                if ((data % 3600) / 60 >= 1) {
                  str = str + ((data % 3600) - (data % 60)) / 60 + "分";
                }
                str = str + Math.floor(data % 60) + "秒";
                arr.push(str);
                if (all == 0) {
                  percentage.push(0);
                } else {
                  percentage.push(((data / all) * 100).toFixed(2) * 1);
                }
                if (data) {
                  return (label +=
                    item.marker +
                    item.seriesName +
                    '<span style="color:' +
                    item.color +
                    ';display:inline-block;margin:2px 0px 1px 20px">' +
                    arr[index] +
                    '<span style=" font-weight: 700;">' +
                    "(" +
                    percentage[index] +
                    "%" +
                    ")" +
                    "</span>" +
                    "</span>" +
                    "<br/>");
                }
              });
              return '<div style="text-align:left">' + label + "</div>";
            },
            // formatter:`{a}:{c} %<br />
            // {a1}:{c1} %<br />
            // {a2}:{c2} %<br />
            // {a3}:{c3} %
            // `
          },
          legend: {
            data: legendData,
            icon: "circle",
            textStyle: {
              color: color,
              fontsize: 10,
              padding: [0, 0, 0, -10],
            },
            itemHeight: 10, //修改图形大小
            itemGap: 15, //修改间距
          },
          dataZoom: [
            {
              type: "inside",
              yAxisIndex: [0],
              start: 0,
              end: 100,
            },
            // {
            //   type: "slider", //给x轴设置滚动条
            //   show: true, //flase直接隐藏图形
            //   yAxisIndex: [0],
            //   bottom: 0,
            //   height: 40,
            //   showDetail: false,
            //   startValue: 0, //滚动条的起始位置
            //   endValue: 100, //滚动条的截止位置（按比例分割你的柱状图x轴长度）
            // },
          ],
          grid: {
            left: "0",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          yAxis: [
            {
              type: "category",
              data: xdata,
              axisLabel: {
                //文字倾斜
                // interval: 0,
                rotate: 40,
                textStyle: {
                  color: color,
                },
                formatter: function (value) {
                  if (value.length > 7) {
                    return value.substring(0, 7) + "...";
                  } else {
                    return value;
                  }
                },
              },
            },
            // {
            //   type: 'value',
            //   min: 0,
            //   max: 100,
            //   axisLabel: {
            //   formatter: '{value} %'
            //  }
            // }
          ],
          xAxis: [
            {
              type: "value",
              axisLabel: {
                formatter: "{value} 时",
                textStyle: {
                  color: color,
                },
                rotate: 40,
              },
              min: 0,
            },
          ],
          series: data,
        };
        var chartDom = document.getElementById(ref);
        let myChart = echarts.init(chartDom);
        myChart.clear();
        myChart.setOption(option);
      },
      setBar1(data, xdata, ref) {
        let color = "#383838";
        let option = {
          title: {
            text: "异常机台排名",
            left: "left",
            textStyle: {
              fontSize: 12,
            },
          },
          tooltip: {
            position: function (point, params, dom, rect, size) {
              // 鼠标坐标和提示框位置的参考坐标系是：以外层div的左上角那一点为原点，x轴向右，y轴向下
              // 提示框位置
              var x = 0; // x坐标位置
              var y = 0; // y坐标位置

              // 当前鼠标位置
              var pointX = point[0];
              var pointY = point[1];

              // 外层div大小
              // var viewWidth = size.viewSize[0];
              // var viewHeight = size.viewSize[1];

              // 提示框大小
              var boxWidth = size.contentSize[0];
              var boxHeight = size.contentSize[1];

              // boxWidth > pointX 说明鼠标左边放不下提示框
              if (boxWidth > pointX) {
                x = 5;
              } else {
                // 左边放的下
                x = pointX - boxWidth;
              }

              // boxHeight > pointY 说明鼠标上边放不下提示框
              if (boxHeight > pointY) {
                y = 5;
              } else {
                // 上边放得下
                y = pointY - boxHeight;
              }

              return [x - 40, y];
            },
            trigger: "axis",
            axisPointer: {
              type: "cross",
            },
            formatter: function (params) {
              let arr = [];
              let all = Number(params[0].data * 3600 + params[1].data * 3600);
              let percentage = [];
              params.forEach((item) => {
                let data = item.data * 3600;
                let str = "";
                if (data / 3600 >= 1) {
                  str = str + (data - (data % 3600)) / 3600 + "小时";
                }
                if ((data % 3600) / 60 >= 1) {
                  str = str + ((data % 3600) - (data % 60)) / 60 + "分";
                }
                str = str + Math.floor(data % 60) + "秒";
                arr.push(str);
                if (all == 0) {
                  percentage.push(0);
                } else {
                  percentage.push(((data / all) * 100).toFixed(2) * 1);
                }
              });
              return (
                "<div style='font-size:12px'>" +
                params[0].axisValue +
                "<br/>" +
                params[0].marker +
                params[0].seriesName +
                '<span style="color: #ffb933;display:inline-block;">' +
                arr[0] +
                '<span style=" font-weight: 700;">' +
                "(" +
                percentage[0] +
                "%" +
                ")" +
                "</span>" +
                "</span>" +
                "<br/>" +
                params[1].marker +
                params[1].seriesName +
                '<span style="color:#ff0000;display:inline-block;">' +
                arr[1] +
                '<span style=" font-weight: 700;">' +
                "(" +
                percentage[1] +
                "%" +
                ")" +
                "</span>" +
                "</span>" +
                "<br/>" +
                // params[2].marker + params[2].seriesName + '<span style="color: #ff0000;display:inline-block;margin:1px 0px 1px 30px">' + arr[2]  +'<span style=" font-weight: 700;">'+'(' +percentage[2]+'%'+')'+'</span>' + '</span>' + '<br/>' +
                // params[3].marker + params[3].seriesName + '<span style="color: #808080;display:inline-block;margin:1px 0px 1px 30px">' + arr[3]  +'<span style=" font-weight: 700;">'+'(' +percentage[3]+'%'+')'+'</span>' + '</span>' + '<br/>' +
                "</div>"
              );
            },
            // formatter:`{a}:{c} %<br />
            // {a1}:{c1} %<br />
            // {a2}:{c2} %<br />
            // {a3}:{c3} %
            // `
          },
          legend: {
            right: 0,
            icon: "circle",
            textStyle: {
              color: color,
              fontsize: 10,
              padding: [0, 0, 0, -10],
            },
            itemHeight: 10, //修改图形大小
            itemGap: 15, //修改间距
          },
          grid: {
            top: "20",
            left: "0",
            bottom: "3%",
            containLabel: true,
          },
          dataZoom: [
            {
              type: "inside",
              yAxisIndex: [0],
              start: 0,
              end: 100,
            },
          ],
          xAxis: [
            {
              type: "value",
              axisLabel: {
                interval: 0,
                rotate: 40,
                formatter: "{value} 时",
                textStyle: {
                  color: color,
                },
              },
              min: 0,
            },
          ],
          yAxis: [
            {
              type: "category",
              data: xdata,
              axisLabel: {
                // interval: 0, //文字全部显示
                rotate: 40,
                textStyle: {
                  color: color,
                },
                formatter: function (value) {
                  if (value.length > 7) {
                    return value.substring(0, 7) + "...";
                  } else {
                    return value;
                  }
                },
              },
              // alignTicks:true,
            },
          ],
          series: data,
        };
        var chartDom = document.getElementById(ref);
        let myChart = echarts.init(chartDom);
        myChart.clear();
        myChart.setOption(option);
      },
      setPie1(data, ref) {
        let legendDate = [];
        data = data.filter((item) => {
          if (item.value > 0) {
            legendDate.push({ name: item.name, icon: "circle" });
            return item;
          }
        });
        let color = "#383838";
        let option = {
          title: {
            text: "稼动率",
            left: "left",
            textStyle: {
              fontSize: 12,
            },
          },
          legend: {
            orient: "vertical",
            left: "70%", //图例距离左的距离
            top: "center",
            data: legendDate,
            textStyle: {
              fontsize: 8,
              padding: [0, 0, 0, -10],
              color: color,
            },
            itemHeight: 10, //修改图形大小
            itemGap: 15, //修改间距
          },
          series: [
            {
              name: "Access From",
              type: "pie",
              radius: "70%",
              center: ["37%", "50%"],
              data: data,
              label: {
                normal: {
                  color: "#e8e8e8",
                  position: "inner", // 设置标签位置，默认在饼状图外 可选值：'outer' ¦ 'inner（饼状图上）'
                  // formatter: '{a} {b} : {c}个 ({d}%)'   设置标签显示内容 ，默认显示{b}
                  // {a}指series.name  {b}指series.data的name
                  // {c}指series.data的value  {d}%指这一部分占总数的百分比
                  formatter: "{d|{d}%} \n {b|{b}:{c}小时}",
                  rich: {
                    d: {
                      color: "#fff",
                      fontSize: 12,
                      lineHeight: 10,
                      height: 10,
                    },
                    b: {
                      color: "#0000ff",
                      fontSize: 12,
                      lineHeight: 10,
                      align: "left",
                    },
                  },
                },
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        };
        var chartDom = document.getElementById(ref);
        let myChart = echarts.init(chartDom);
        myChart.clear();
        myChart.setOption(option);
      },
      setPie2(data, ref) {
        let legendDate = [];
        data = data.filter((item) => {
          if (item.value > 0) {
            legendDate.push({ name: item.name, icon: "circle" });
            return item;
          }
        });
        let color = "#383838";
        // data=data.filter((item)=>item.value>0)
        let option = {
          title: {
            text: "当前机台状态",
            left: "left",
            textStyle: {
              fontSize: 12,
            },
          },
          legend: {
            orient: "vertical",
            left: "70%", //图例距离左的距离
            top: "center",
            data: legendDate,
            textStyle: {
              fontsize: 8,
              padding: [0, 0, 0, -10],
              color: color,
            },
            itemHeight: 10, //修改图形大小
            itemGap: 15, //修改间距
          },
          series: [
            {
              name: "Access From",
              type: "pie",
              radius: "70%",
              center: ["37%", "50%"],
              data: data,
              label: {
                normal: {
                  color: "#e8e8e8",
                  position: "inner", // 设置标签位置，默认在饼状图外 可选值：'outer' ¦ 'inner（饼状图上）'
                  // formatter: '{a} {b} : {c}个 ({d}%)'   设置标签显示内容 ，默认显示{b}
                  // {a}指series.name  {b}指series.data的name
                  // {c}指series.data的value  {d}%指这一部分占总数的百分比
                  formatter: "{b}" + "{c}" + "台",
                },
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        };
        var chartDom = document.getElementById(ref);
        let myChart = echarts.init(chartDom);
        myChart.clear();
        myChart.setOption(option);
      },
      setFrequency(data, ref) {
        let legendDate = [];
        data = data.filter((item) => {
          if (item.value > 0) {
            legendDate.push({ name: item.name, icon: "circle" });
            return item;
          }
        });
        let option = {
          title: {
            text: "原因时长总计",
            left: "left",
            textStyle: {
              fontSize: 12,
            },
          },
          legend: {
            bottom: "5%",
            type: "scroll",
            orient: "vertical",
            left: "55%",
            // top: "center",
            data: legendDate,
            textStyle: {
              fontsize: 8,
              padding: [0, 0, 0, -10],
            },
            itemHeight: 10, //修改图形大小
            itemGap: 8, //修改间距
            formatter: function (name) {
              // 获取legend显示内容
              let data1 = data; //这个是展示的数据
              let total = 0;
              let tarValue = 0;
              let value = 0;
              for (let i = 0, l = data1.length; i < l; i++) {
                total += data1[i].value;
                if (data1[i].name == name) {
                  tarValue = data1[i].value;
                  value = data1[i].value;
                }
              }
              let p = total == 0 ? 0 : ((tarValue / total) * 100).toFixed(1);
              return echarts.format.truncateText(
                name,
                40,
                "14px Microsoft Yahei",
                "…" //如果宽度超过80会出现...
              );
            },
          },
          tooltip: {
            position: [-90, -30],
            // position: function (point, params, dom, rect, size) {
            //   // 鼠标坐标和提示框位置的参考坐标系是：以外层div的左上角那一点为原点，x轴向右，y轴向下
            //   // 提示框位置
            //   var x = 0; // x坐标位置
            //   var y = 0; // y坐标位置

            //   // 当前鼠标位置
            //   var pointX = point[0];
            //   var pointY = point[1];

            //   // 外层div大小
            //   // var viewWidth = size.viewSize[0];
            //   // var viewHeight = size.viewSize[1];

            //   // 提示框大小
            //   var boxWidth = size.contentSize[0];
            //   var boxHeight = size.contentSize[1];

            //   // boxWidth > pointX 说明鼠标左边放不下提示框
            //   if (boxWidth > pointX) {
            //     x = 5;
            //   } else {
            //     // 左边放的下
            //     x = pointX - boxWidth;
            //   }

            //   // boxHeight > pointY 说明鼠标上边放不下提示框
            //   if (boxHeight > pointY) {
            //     y = 5;
            //   } else {
            //     // 上边放得下
            //     y = pointY - boxHeight;
            //   }

            //   return [x , y];
            // },
            trigger: "item",
            formatter: function (params) {
              const h = parseInt(params.value / 3600);
              const minute = parseInt((params.value / 60) % 60);
              const second = Math.ceil(params.value % 60);
              const hours = h < 10 ? "0" + h : h;
              const formatSecond = second > 59 ? 59 : second;
              return (
                "<div style='text-align:left'>" +
                '<span style="font-size:12px;font-weigth:700;margin-right:10px;color:#1a73e8">' +
                params.name +
                "</span>" +
                `${hours > 0 ? `${hours}时:` : ""}${
                  minute < 10 ? "0" + minute + "分" : minute + "分"
                }:${
                  formatSecond < 10
                    ? "0" + formatSecond + "秒"
                    : formatSecond + "秒"
                }` +
                "<br/>" +
                '<span style="font-size:12px;font-weigth:700;margin-right:10px">' +
                "时长占比" +
                "</span>" +
                params.percent +
                "%" +
                "</div>"
              );
            },
          },
          series: [
            {
              name: "异常分布图",
              type: "pie",
              center: ["35%", "56%"],
              radius: ["35%", "60%"],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: "center",
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: 6,
                  fontWeight: "bold",
                },
              },
              labelLine: {
                show: false,
              },
              data: data,
            },
          ],
        };
        var chartDom = document.getElementById(ref);
        let myChart = echarts.init(chartDom);
        myChart.clear();
        myChart.setOption(option);
      },
    };
  },
  methods: {
    getShiftGroup(val) {
      if (val == 2) {
        this.$api.shiftSet
          .allShiftList(this.$route.query.projectGroupId)
          .then((res) => {
            if (res.code == 200) {
              this.options = res.data.map((item) => ({
                text: item.shiftGroupName,
                value: item.shiftGroupId,
                children: [],
              }));
            }
          });
      }
    },
    onChange({ value }) {
      this.$api.shiftSet.getShift(value).then((res) => {
        if (res.code == 200) {
          let children = res.data.map((item) => ({
            text: item.shiftName,
            value: item.shiftId,
          }));
          this.options.find((it) => {
            if (it.value == value) {
              it.children = children;
            }
          });
        }
      });
    },
    onFinish({ selectedOptions }) {
      this.show = false;
      this.fieldValue = selectedOptions.map((option) => option.text).join("/");
    },
    tabsChange(name, title) {
      if (name == "b") {
        this.$nextTick(() => {
          this.$refs.trendChart.getUseRate();
        });
      } else {
        that.getUseRate();
      }
    },
    //改变日期
    datechange(val) {
      if (that.radio == 3) {
        let day = this.dayjs(val).unix();
        that.addDate = this.dayjs.unix(day - 86400).format("YYYY-MM-DD");
        that.weekValue = that.addDate;
      } else {
        that.addDate = val;
      }
    },
    getCausePie(from) {
      let pieData = [];
      that.$api.setCause.getproGroupAllList(from).then((res) => {
        if (res.code == 200) {
          res.data.forEach((item) => {
            pieData.push({ value: item.duration, name: item.causeName });
          });
        }
        this.setFrequency(pieData, "pie2");
      });
    },
    getUseRate() {
      let from = {
        date: that.addDate,
        projectGroupId: this.$route.query.projectGroupId,
        type: this.radio,
        shiftId: this.shiftValue,
      };
      that.getAllStateList(); //获取机台状态
      //  that.getCausePie(from) //获取原因
      that.$api.triColorLamp.getAllList(from).then((res) => {
        if (res.code == 200) {
          this.dateVisible = false;
          let barList = [];
          let date = [];
          let red = 0;
          let green = 0;
          let yellow = 0;
          let sortList = [];
          if (res.code == 200) {
            res.data.forEach((item) => {
              let sortNumber = item.duration["1"] + item.duration["2"];
              let number =
                item.duration["0"] +
                item.duration["1"] +
                item.duration["2"] +
                item.duration["3"] +
                item.duration["4"];
              if (number !== 0 && number) {
                date.push(item.projectName);
                barList.push(item.duration);
                if (sortNumber !== 0 && sortNumber) {
                  sortList.push({ ...item.duration, name: item.projectName });
                }
              }
            });
          }
          barList.forEach((item) => {
            red += item["1"];
            yellow += item["2"];
            green += item["3"];
          });
          let data = [
            {
              value: (green / 3600).toFixed(2),
              name: "绿灯",
              itemStyle: { color: "#60b933" },
            },
            {
              value: (yellow / 3600).toFixed(2),
              name: "黄灯",
              itemStyle: { color: "#ffb933" },
            },
            {
              value: (red / 3600).toFixed(2),
              name: "红灯",
              itemStyle: { color: "#ff0000" },
            },
          ];
          that.setPie1(data, "pie1");
          that.setBarList(barList, date);
          that.setAllRanking(sortList);
          that.flag = false;
        }
      });
    },
    // 下方柱状图
    setBarList(ALLdata, date) {
      let red = [];
      let yellow = [];
      let grey = [];
      let green = [];
      let blue = [];
      let Xdata = [...date];
      let percentage = [];
      ALLdata.forEach((item) => {
        let newGrey = (item["0"] / 3600).toFixed(4) * 1;
        let newred = (item["1"] / 3600).toFixed(4) * 1;
        let newyellow = (item["2"] / 3600).toFixed(4) * 1;
        let newgreen = (item["3"] / 3600).toFixed(4) * 1;
        let newblue = (item["4"] / 3600).toFixed(4) * 1;
        let allPercentage = newGrey + newred + newyellow + newgreen + newblue;
        let newpercentage = (newgreen / allPercentage).toFixed(2) * 100;
        red.push(newred);
        yellow.push(newyellow);
        green.push(newgreen);
        grey.push(newGrey);
        blue.push(newblue);
        percentage.push(newpercentage);
      });
      let data = [
        {
          name: "绿灯",
          type: "bar",
          stack: "Ad",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            normal: {
              color: "rgb(96,185,51)",
              // label: {
              //   show: true,
              //   formatter:'绿灯'+'{c}',
              // }
            },
          },
          data: green,
        },
        {
          name: "黄灯",
          type: "bar",
          stack: "Ad",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            normal: {
              color: "rgb(255,185,51)",
              // label: {
              //   show: true,
              //   formatter:'黄灯'+'{c}',
              // }
            },
          },
          data: yellow,
        },
        {
          name: "红灯",
          type: "bar",
          stack: "Ad",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            normal: {
              color: "red",
              // label: {
              //   show: true,
              //   formatter:'红灯'+'{c}',
              // }
            },
          },
          data: red,
        },
        {
          name: "灭灯",
          type: "bar",
          stack: "Ad",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            normal: {
              color: "grey",
              // label: {
              //   show: true,
              //   formatter: '灭灯' + '{c}',
              //   fontSize:'12'
              // }
            },
          },
          data: grey,
        },
      ];
      //  let flag= blue.reduce((pre, curr) => {
      //     return pre=pre+curr
      //  }, 0)
      // if (flag) {
      data.push({
        name: "蓝灯",
        type: "bar",
        stack: "Ad",
        barMaxWidth: 40,
        emphasis: {
          focus: "none",
          disabled: true,
        },
        itemStyle: {
          normal: {
            color: "#7ed3f4",
            // label: {
            //   show: true,
            //   formatter:'绿灯'+'{c}',
            // }
          },
        },
        data: blue,
      });

      that.setBar(data, Xdata, "barBox");
    },
    setAllRanking(ALLdata) {
      ALLdata = ALLdata.filter((item) => item["1"] + item["2"] != 0);
      ALLdata.sort(function (a, b) {
        return a["1"] + a["2"] - (b["1"] + b["2"]);
      });
      let red = [];
      let yellow = [];
      let grey = [];
      let green = [];
      let Xdata = [];
      ALLdata.forEach((item) => {
        let newGrey = (item["0"] / 3600).toFixed(4) * 1;
        let newred = (item["1"] / 3600).toFixed(4) * 1;
        let newyellow = (item["2"] / 3600).toFixed(4) * 1;
        let newgreen = (item["3"] / 3600).toFixed(4) * 1;
        red.push(newred);
        yellow.push(newyellow);
        green.push(newgreen);
        grey.push(newGrey);
        Xdata.push(item.name);
      });

      let data = [
        {
          name: "黄灯",
          type: "bar",
          stack: "Ad",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "rgb(255,185,51)",
          },
          data: yellow,
        },
        {
          name: "红灯",
          type: "bar",
          stack: "Ad",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "red",
          },
          data: red,
        },
      ];
      that.setBar1(data, Xdata, "ranking");
    },
    //获取灯的所有状态
    getAllStateList() {
      const data = {
        projectGroupId: this.$route.query.projectGroupId,
      };
      that.$api.triColorLamp.getAllStateList(data).then((res) => {
        let green = 0;
        let red = 0;
        let yellow = 0;
        let grey = 0;
        let blue = 0;
        res.data.forEach((item) => {
          switch (item.lampState) {
            case 0:
              grey += 1;
              break;
            case 1:
              red += 1;
              break;

            case 2:
              yellow += 1;
              break;

            case 3:
              green += 1;
              break;

            case 4:
              blue += 1;
              break;

            default:
              break;
          }
        });
        let data = [
          { value: green, name: "绿灯", itemStyle: { color: "#60b933" } },
          { value: yellow, name: "黄灯", itemStyle: { color: "#ffb933" } },
          { value: red, name: "红灯", itemStyle: { color: "#ff0000" } },
          { value: blue, name: "蓝灯", itemStyle: { color: "#73c0de" } },
          { value: grey, name: "灭灯", itemStyle: { color: "#808080" } },
        ];
        that.setPie2(data, "pie2");
      });
    },
  },
  mounted() {
    that = this;
    let date = new Date();
    let full = date.getFullYear();
    let month =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    let Day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let endDate = full + "-" + month + "-" + Day;
    if (Day > 6) {
      Day -= 6;
      Day = Day < 10 ? "0" + Day : Day;
    } else {
      if (month - 1 < 1) {
        full -= 1;
        month = 12;
      } else {
        month -= 1;
        if (month < 10) {
          month = "0" + month;
        }
      }
      Day = new Date(full, month, 0).getDate() + Day * 1 - 6;
    }
    let startDate = full + "-" + month + "-" + Day;
    let newDay = this.dayjs(endDate).startOf("week").format("YYYY-MM-DD");
    let day = this.dayjs(newDay).unix();
    that.addDate = endDate;
    this.weekValue = this.dayjs.unix(day + 86400).format("YYYY-MM-DD");
    that.monthValue = full + "-" + month;
    that.dateValue = endDate;
    that.getUseRate();
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.header {
  height: 50px;
  padding: 0px 20px;
  border-radius: 15px 15px 0px 0px;
  width: 98%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.oeebody {
  height: calc(100% - 50px);
  padding: 0 5px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  overflow: scroll;
}
#barBox {
  width: 49%;
  height: 75vh;
  border: 1px solid #dee1e6;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
#pieBox {
  width: 49%;
  height: 75vh;
  border: 1px solid #dee1e6;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
#pie1 {
  width: 100%;
  height: 33%;
  border-bottom: 1px solid #dee1e6;
}
#pie2 {
  width: 100%;
  height: 33%;
  border-bottom: 1px solid #dee1e6;
}
#ranking {
  width: 100%;
  height: 33%;
}
</style>
